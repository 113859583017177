@font-face {
    font-family: 'NunitoSans-Black';
    src: url('../../../public/fonts/NunitoSans-Black.eot');
    src: url('../../../public/fonts/NunitoSans-Black.eot?#iefix') format('embedded-opentype'),
    url('../../../public/fonts/NunitoSans-Black.woff2') format('woff2'),
    url('../../../public/fonts/NunitoSans-Black.woff') format('woff'),
    url('../../../public/fonts/NunitoSans-Black.ttf') format('truetype'),
    url('../../../public/fonts/NunitoSans-Black.svg#NunitoSans-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'NunitoSans-Bold';
    src: url('../../../public/fonts/NunitoSans-Bold.eot');
    src: url('../../../public/fonts/NunitoSans-Bold.eot?#iefix') format('embedded-opentype'),
    url('../../../public/fonts/NunitoSans-Bold.woff2') format('woff2'),
    url('../../../public/fonts/NunitoSans-Bold.woff') format('woff'),
    url('../../../public/fonts/NunitoSans-Bold.ttf') format('truetype'),
    url('../../../public/fonts/NunitoSans-Bold.svg#NunitoSans-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'NunitoSans-ExtraBold';
    src: url('../../../public/fonts/NunitoSans-ExtraBold.eot');
    src: url('../../../public/fonts/NunitoSans-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('../../../public/fonts/NunitoSans-ExtraBold.woff2') format('woff2'),
    url('../../../public/fonts/NunitoSans-ExtraBold.woff') format('woff'),
    url('../../../public/fonts/NunitoSans-ExtraBold.ttf') format('truetype'),
    url('../../../public/fonts/NunitoSans-ExtraBold.svg#NunitoSans-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'NunitoSans-SemiBoldItalic';
    src: url('../../../public/fonts/NunitoSans-SemiBoldItalic.eot');
    src: url('../../../public/fonts/NunitoSans-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../../../public/fonts/NunitoSans-SemiBoldItalic.woff2') format('woff2'),
    url('../../../public/fonts/NunitoSans-SemiBoldItalic.woff') format('woff'),
    url('../../../public/fonts/NunitoSans-SemiBoldItalic.ttf') format('truetype'),
    url('../../../public/fonts/NunitoSans-SemiBoldItalic.svg#NunitoSans-SemiBoldItalic') format('svg');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'NunitoSans';
    src: url('../../../public/fonts/NunitoSans-Regular.eot');
    src: url('../../../public/fonts/NunitoSans-Regular.eot?#iefix') format('embedded-opentype'),
    url('../../../public/fonts/NunitoSans-Regular.woff2') format('woff2'),
    url('../../../public/fonts/NunitoSans-Regular.woff') format('woff'),
    url('../../../public/fonts/NunitoSans-Regular.ttf') format('truetype'),
    url('../../../public/fonts/NunitoSans-Regular.svg#NunitoSans-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'ArimaKoshi-ExtraBold';
    src: url('../../../public/fonts/ArimaKoshi-ExtraBold.eot');
    src: url('../../../public/fonts/ArimaKoshi-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('../../../public/fonts/ArimaKoshi-ExtraBold.woff2') format('woff2'),
    url('../../../public/fonts/ArimaKoshi-ExtraBold.woff') format('woff'),
    url('../../../public/fonts/ArimaKoshi-ExtraBold.ttf') format('truetype'),
    url('../../../public/fonts/ArimaKoshi-ExtraBold.svg#ArimaKoshi-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
h1,h2,h3,h4{
    font-family: 'ArimaKoshi-ExtraBold' !important;
}
p, span{
    font-family: 'NunitoSans';
}
.page-home img {
    width: 100%;
}

#sc_banner>.container {
    height: 100vh;
}
.view-sm h4 {
    font-size: 21px;
    color: #6d381d;
}

.view-sm button {
    color: #6d381d;
    background: transparent;
    border: 2px solid #6d381d;
    margin-bottom: 20px;
    border-radius: 3px;
}
#sc_banner {
    min-height: 100vh;
    background: url('../../../public/images/banner-home.jpeg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    overflow: hidden;
    position: relative;
    padding-top: 100px;
}

#sc_presale {
    min-height: 100vh;
    background: url('../../../public/images/banner-home.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
}

.box-presale {
    background: url('../../../public/images/24467455_ceoy_fac9_220127.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 60px 90px;
}

.box-countdowntime {
    display: inline-block;   
}

.box-countdowntime span.item-time {
    padding: 10px;
    display: inline-block;
    font-size: 20px;
    font-weight: bold;
    min-width: 85px;
    background: #d45350;
    margin-left: 8px;
    margin-right: 8px;
    border-radius: 5px;
}

.box-countdowntime span.item-time span.time-text {
    display: block;
    font-size: 14px;
    color: #000;
    line-height: 15px;
    text-transform: uppercase;
    margin-bottom: 5px;
}

.box-countdowntime span.item-time span.time-t {
    font-size: 28px;
    line-height: 20px;
    color: #fff;
    font-weight: bold;
}

span.item-time-line {
    font-size: 34px;
    font-weight: bold;
    position: relative;
    top: -11px;
}

.tokens-sold-line {
    display: block;
    background: #fff;
    border-radius: 20px;
    position: relative;
    height: 24px;
    overflow: hidden;
}

.tokens-sold-sell {
    background: #39a657;
    display: inline-block;
    height: 24px;
    position: absolute;
    left: 0px;
    border-radius: 20px;
}

.tokens-sold-line span {
    position: absolute;
    display: block;
    text-align: center;
    width: 100%;
    font-weight: bold;
}

.tokens-sold>p {
    text-align: left;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 5px;
    font-size: 18px;
    color: #684638;
}

.tokens-sold-stage {
    color: #684638;
    font-size: 16px;
    margin-top: 7px;
    font-weight: bold;
    text-align: left;
}

.tokens-sold-stage span {
    color: #FF5722;
}
.ip-payment {
    display: grid;
    gap: 20px;
    grid-template-columns: auto auto;
}
.gr-ip-payment {
    position: relative;
}

.gr-ip-payment label {
    display: block;
    text-align: left;
    margin-bottom: 5px;
    color: #684638;
    font-weight: bold;
}

.gr-ip-payment button {
    position: absolute;
    top: 0px;
    right: 0px;
}

.gr-ip-payment input {
    width: 100%;
    height: 42px;
    border-radius: 5px;
    border: 1px solid #684638;
    padding: 5px 10px;
    margin-bottom: 16px;
    font-weight: bold;
    color: #000;
}

button.btn-payment-max {   
    border: 0px;
    border-radius: 3px;
    background: transparent;
    font-size: 14px;
}

.coin-rate {
    width: 100%;
    text-align: center !important;
    margin-top: 10px;   
}

.coin-rate p {
    position: relative;
    display: flex;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
    color: rgb(215 83 83);
}
.text-next {
    background: #ce5d53;
    border-top: 3px solid #fff;
    border-bottom: 3px solid #fff;
    margin-top: 30px;
    margin-bottom: 20px;
    font-weight: bold;
    color: #fff;
}
.coin-rate div hr {
    display: inline-block;
    border-width: 2px;
    flex: 1 1;
    border-color: #000000;
    opacity: 1;
}
.coin-rate div span {
    z-index: 10;
    background: transparent;
    display: inline-block;
    padding: 4px 20px;
    position: relative;
    opacity: 1 !important;
    border-radius: 3px;
    color: #000;
    font-weight: bold;
}
.box-menber2{
    display: none;
}
.type-pay button {
    width: calc(50% - 5px);
    font-weight: bold;
    height: 40px;
    border-radius: 4px;
    border: 2px solid #674539;
    color: #674539;
    background: #fff;
}

button.btn-payment-bnb {
    margin-right: 10px;
}

.type-pay button.active {
    background: #d75353;
    color: #fff;
    border-color: #d75353;
}

.total-wallet {
    text-align: left;
    margin-top: 15px;
    font-weight: bold;
    color: #684638;
}

.gr-btn-payment button {
    min-width: 200px;
    background: #db464e;
    border: 1px solid #db464e;
    font-weight: bold;
    color: #fff;
    font-size: 20px;
    padding: 10px 35px;
    border-radius: 5px;
}

.gr-btn-payment {
    padding-bottom: 25px;
    padding-top: 20px;
}

.height100 {
    height: 100vh;
}

div#sc_presale {
    padding: 100px 0px;
}
div#sc_member {
    padding: 15px;
    background: url('../../../public/images/3156029.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
}
div#bg_member {
    padding: 20px;
    color: #fff;
    display: grid;
    grid-template-columns: auto ;
}
div#bg_member span {
    font-size: 38px;
    text-shadow: -1px -1px 0px #ffffff, 1px -1px 0 #ffffff, -1px 1px 0 #fff, 1px 1px 0 #fff;
    color: #f04162;
    font-weight: bold;
    font-family: 'NunitoSans-Bold';
}

div#bg_member span {
    font-size: 36px;
}
div#sc_about {
    min-height: 100vh;
    padding-top: 100px;
    position: relative;
    overflow: hidden;
}

div#sc_about h2 {
    font-size: 64px;
    margin-bottom: 60px;
    color: #e5364e;
    font-weight: bold;
    text-shadow: -1px -1px 0px #000, 1px -1px 0 #fff, -1px 1px 0 #000, 1px 1px 0px #fff;
}

.title-about {
    position: relative;
}
div#sc_about p {
    font-weight: bold;
}

div#sc_about p {
    font-size: 22px;
}



.icon-about img.iabout2 {
    width: 100%;
    max-width: 320px;
}

.icon-about img.iabout1 {
    width: 100%;
    max-width: 150px;
    position: absolute;
    bottom: 67px;
    right: 30px;
}

.icon-about .box-icon-about {
    position: relative;
}

.wapper-bg {
    background: linear-gradient(92deg, #FF5F6D, #FFC371);
}



#sc_roadmap {
    background: url('../../../public/images/bg-map.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}

div#bg_roadmap {
    background: rgb(0 0 0 / 15%);
    padding-top: 100px;
    padding-bottom: 100px;
    color: #fff;
    overflow: hidden;
}
.timeline-content li {
    color: #979797;
    margin-bottom: 18px;
}

.main-timeline {
    position: relative;
}

.main-timeline:before {
    content: "";
    height: calc(100% + 100px);
    border-left: 3px dashed #fff;
    position: absolute;
    z-index: 99;
    left: 50%;
    top: -50px;
}
div#sc_tokenomics {
    padding-top: 100px;
    padding-bottom: 100px;
}

.item-tokenomics-img {
    height: 300px;
    text-align: center;
}

.item-tokenomics-img img {
    height: 100%;
    width: auto;
}

.item-tokenomics-main {
    text-align: center;
    margin-top: 10px;
}

.item-tokenomics-main h3 {
    color: rgb(112, 53, 25);
    font-size: 22px;
    font-weight: bold;
}

.item-tokenomics-main h4 {
    color: #cd400c;
    font-size: 26px;
    font-weight: bold;
}


div#sc_tokenomics h2 {
    font-size: 64px;
    margin-bottom: 60px;
}

.main-timeline {
    font-family: 'Poppins', sans-serif;
    padding: 25px 10px;
}

.main-timeline:after {
    content: '';
    display: block;
    clear: both;
}



.main-timeline .title {
    color: #fff;
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 0.5px;
    text-align: center;
    text-transform: uppercase;
    padding: 12px;
}

.main-timeline .description {
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 1px;
    color: #000;
}


.timeline-content { 
    padding: 40px 30px !important;
    width: 40%;
    margin-bottom: 50px;
    position: relative;
    border-radius: 8px;
    background: linear-gradient(92deg, #FF5F6D, #FFC371);
}

.main-timeline .timeline:nth-child(even) .timeline-content {
    margin-left: 60%;
}

.main-timeline .timeline:nth-child(2) .timeline-content {
    width: 40%;
    margin-top: 30px;
}

.main-timeline .timeline:nth-child(3) .timeline-content {
    width: 60%;
    margin-top: 30px;  
}

.main-timeline .timeline:nth-child(2) .timeline-content {
    width: 40%;
    margin-top: 30px;
}

.main-timeline .timeline:nth-child(3) .timeline-content {
    width: 40%;
    margin-top: 30px;
}

.main-timeline .timeline:nth-child(5) .timeline-content {
    width: 60%;
}
.timeline-content li {
    color: #fff;
    margin-bottom: 18px;
}

.main-timeline {
    position: relative;
}

.main-timeline:before {
    content: "";
    height: calc(100% + 100px);
    border-left: 3px dashed #fff;
    position: absolute;
    z-index: 99;
    left: 50%;
    top: -50px;
}

.timeline-content:before {
    content: "";
    position: absolute;
    background: #ffffff;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    border: 10px solid #b2b2b2;
    display: inline-block;
    right: -138px;
    top: 0px;
}

.main-timeline .timeline:nth-child(2) .timeline-content:before {
    right: auto;
    left: -134px;
}

.timeline-content:after {
    content: "";
    width: 50px;
    border-top: 3px dashed #fff;
    position: absolute;
    z-index: 99;
    top: 19px;
    right: -98px;
}

.main-timeline .timeline:nth-child(2) .timeline-content:after {
    right: auto;
    left: -94px;
}
.timeline-icon {
    position: absolute;
    top: -20px;
}

.timeline-icon img {
    max-width: 100px;
}

.main-timeline .timeline:nth-child(5) .timeline-content {
    width: 60%;
}

div#sc_roadmap .title-h {
    font-size: 64px;
    margin-bottom: 120px;
    margin-top: 100px;
    position: relative;
    text-transform: uppercase;
    
}

div#sc_roadmap .title-h span {
    position: relative;
}


.height100 {
    height: 100%;
}

.bn-img img {
    animation: circleScale 3s infinite;
}

@keyframes circleScale {
    0% {
        transform: scale(0.8);
    }

    50% {
        transform: scale(1);
    }

    100% {
        transform: scale(0.8);
    }
}
.contact-img img {
    transform: scaleX(-1);
    max-width: 375px;
}
div#sc_banner h1 {
    color: #2a3f5c;
    font-size: 65px;
    font-weight: bold;
    margin-bottom: 25px;
}

div#sc_banner h5 {
    color: #3dab5e;
    font-size: 35px;
    font-weight: bold;
    margin-bottom: 0px;
}

div#sc_banner p {
    font-size: 22px;
}

p.presale-text1 {
    font-weight: bold;
    font-size: 18px;
    color: #684638;
}

.box-presale h2 {
    font-weight: bold;
    text-align: center;
    color: rgb(112, 53, 25);
    margin-top: 20px;
    font-size: 24px;
    margin-bottom: 30px;
}
#sc_rankings{
    background: url('../../../public/images/rangking.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
}
#bg_rankings {
    background: rgb(0 0 0 / 60%);
    padding-top: 100px;
    padding-bottom: 100px;
    color: #fff;
    overflow: hidden;
}
.ip-payment {
    display: grid;
    gap: 20px;
    grid-template-columns: auto auto;
}

.box-rankings {
    border: 11px solid #8b2832;
    border-radius: 20px;
    background: #bc3b48;
    padding: 45px 30px 20px;
}

.ranking-item {
    background: #f5b650;
    margin-bottom: 15px;
    display: grid;
    grid-template-columns: 55px auto auto;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 2px 2px 5px 1px #000;
}

.ranking-item-stt span {
    display: block;
    font-size: 30px;
    border-radius: 100%;
    font-weight: bold;
    text-shadow: -1px -1px 4px #872220, 1px -1px 4px #872220, -1px 1px 4px #872220, 1px 1px 4px #872220;
}

.ranking-item-name {
    font-size: 30px;
    text-align: left;
    color: #8c121d;
    font-weight: bold;
}
.ranking-item-name svg {
    color: #fff;
    margin-right: 9px;
}
.ranking-item-name span{
    font-family: 'NunitoSans-ExtraBold';
}
.box-rankings h3 {
    text-shadow: -1px -1px 0px #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    color: #fff;
    font-size: 55px;
    margin-bottom: 30px;
    font-weight: bold;
}
.box-rankings h3 span {
    position: relative;
}
.ranking-item-stt {
    max-width: 65px;
}

.box-rankings h3 span:before{
    content: "";
    position: absolute;
    width: 50px;
    height: 50px;
    background: url('../../../public/images/title-ranking.png');
    background-repeat: no-repeat;
    background-size: 100%;
    z-index: 99;
    left: -27px;
    top: -21px;
}
.ranking-item-point > span {
    background: #cd9f79;
    padding: 3px 7px;
    border-radius: 5px;
    text-shadow: -1px -1px 0px #872220, 1px -1px 0px #872220, -1px 1px 0px #872220, 1px 1px 0px #872220;
}

.ranking-item-point > span svg {
    color: #ffeb3b;
}

.ranking-item-point {
    text-align: right;
    padding-top: 10px;
    padding-right: 13px;
    font-size: 18px;
    font-weight: bold;
}
.box-leaderboard {
    text-align: left;
    max-width: 500px;
    font-size: 17px;
}

.box-leaderboard h4 {
    font-size: 55px;
    margin-bottom: 37px;
    color: #fff;
    font-weight: bold;
    text-shadow: -1px -1px 0px #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
}

.box-leaderboard ul li {
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 18px;
    color: #FF9800;
}

#sc_tokenomics {
    background: linear-gradient(43deg, #185a9d 0px, #43cea2 100%);
    background-size: 100%;
    min-height: 100vh;
    background-position: center;
}

div#sc_tokenomics h2 {
    color: #fff;
    font-weight: bold;
    font-size: 64px;
    font-family: 'NunitoSans-Bold' !important;
    text-transform: uppercase;
    margin-top: 50px;
}

.tokenomics-info h2 span {
    color: #2d7d9d;
    text-shadow: -1px -1px 1px #fff, 1px -1px 1px #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
    font-weight: bold;
}

.tokenomics-info h4 {
    color: #fff;
}

.tokenomics-info {
    color: #fff;
}

.tokenomics-info ul li {
    list-style: none;
    margin-bottom: 15px;
    position: relative;
    font-weight: bold;
}

.tokenomics-info ul {
    margin-top: 30px;
}
.tokenomics-info ul li:before {
    border: 1px solid #fff;
}
li.tokenomics1:before {
    content: "";
    width: 20px;
    height: 20px;
    background: #f74a38;
    position: absolute;
    border-radius: 100%;
    left: -32px;
    top: 2px;
}

li.tokenomics2:before {
    content: "";
    width: 20px;
    height: 20px;
    background: #fa9657;
    position: absolute;
    border-radius: 100%;
    left: -32px;
    top: 2px;
}
li.tokenomics3:before {
    content: "";
    width: 20px;
    height: 20px;
    background: #4a76f9;
    position: absolute;
    border-radius: 100%;
    left: -32px;
    top: 2px;
}
li.tokenomics4:before {
    content: "";
    width: 20px;
    height: 20px;
    background: #5b3c65;
    position: absolute;
    border-radius: 100%;
    left: -32px;
    top: 2px;
}
li.tokenomics5:before {
    content: "";
    width: 20px;
    height: 20px;
    background: #305f95;
    position: absolute;
    border-radius: 100%;
    left: -32px;
    top: 2px;
}
li.tokenomics6:before {
    content: "";
    width: 20px;
    height: 20px;
    background: #3eba6b;
    position: absolute;
    border-radius: 100%;
    left: -32px;
    top: 2px;
}
.contact-img img {
    transform: scaleX(-1);
    max-width: 375px;
}

div#bg_contact {
    background: linear-gradient(181deg, #cf64e0, #112760);
    min-height: 100vh;
    color: #fff;
    padding: 140px;
}

#sc_contact h2 {
    font-size: 64px;
    margin-bottom: 50px;
}

#sc_contact p {
    font-size: 18px;
    text-align: center;
    margin-bottom: 60px;
}

.fr-contact input {
    width: 300px;
    height: 40px;
    border-radius: 18px;
    padding: 7px 14px;
    font-size: 15px;
    margin-bottom: 20px;
    background: transparent;
    border: 1px solid #fff;
    color: #fff;
}

.fr-contact textarea {
    width: 300px;
    border-radius: 18px;
    padding: 7px 14px;
    font-size: 15px;
    margin-bottom: 20px;
    background: transparent;
    border: 1px solid #fff;
    color: #fff;
}
button.btn-contact {
    background: rgb(87, 101, 242);
    color: #fff;
    min-width: 120px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid rgb(87, 101, 242);
    font-size: 17px;
}


footer.home.py-3.my-4 {
    background: #112760;
    margin: 0px !important;
    color: #fff;
}

footer p,
footer a {
    color: #fff !important;
}
footer .container{
    border-top: 1px solid #fff;
    padding-top: 25px;
}
footer a {
    font-size: 20px !important;
}
.ktc a{
    margin-right: 15px;
}
.ktc a svg{
    font-size: 22px;
}
.copyright{
    text-align: right;
}
.logo-footer .logo {
    width: 120px;
    margin: 0px;
}

.logo-footer {
    text-align: center;
    padding: 20px 0px;
}

button.btn.btn-register-now {
    background: #F44336;
    border: 1px solid #F44336;
    font-weight: bold;
    color: #fff;
    font-size: 20px;
    padding: 7px 15px;
    border-radius: 5px;
    margin-top: 20px;
    animation: shake2 2s infinite;
}

@keyframes shake2 {
    0% {
        transform: translateX(0);
    }

    25% {
        transform: translateX(-5px) rotate(-5deg);
    }

    50% {
        transform: translateX(5px) rotate(5deg);
    }

    75% {
        transform: translateX(-5px) rotate(-5deg);
    }

    100% {
        transform: translateX(0);
    }
}

.txt-tb {
    color: #f3450e;
    font-size: 22px;
}
.fr-contact input::placeholder,
.fr-contact textarea::placeholder{
color: #fff;
}


#sc_team_op {
    position: relative;
    z-index: 10;
    padding-top: 120px;
    padding-bottom: 120px;
}


.view-sm button {
    overflow-wrap: break-word;
    width: 100%;
}
.item-saleround-main h3 {
    color: rgb(100 41 14);
    font-weight: bold;
}
button.btn-coming-soon {
    background: #b54649;
    color: #fff;
    border-radius: 5px;
    padding: 9px 30px;
    font-size: 18px;
    border: 0px;
}
.contact-msg p {
    margin-bottom: 10px !important;
}

.text-dex {
    color: rgb(112, 53, 25);
    font-family: 'NunitoSans-Bold';
    font-weight: bold;
    font-size: 22px;
}
.coin-rate > div {
    display: flex;
    margin-bottom: 20px;
}
.list-partner {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 50px;
}
.item-partner{   
    background-size: 100% 100%;
    position: relative;  
    padding: 30px 20px;
}
.list-partner {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 25px;
}

.item-partner-img {
    height: 130px;
}

.item-partner-img img {
    height: 100%;
    width: auto;
}

.item-partner h4 {
    font-size: 20px;
    margin-top: 12px;
    font-weight: bold;
    margin-bottom: 20px;
}


div#sc_partners {
    background: url('../../../public/images/partners.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    padding-top: 100px;
    padding-bottom: 150px;
}

div#sc_partners h2 {
    font-size: 64px;
    margin-bottom: 40px;
    margin-top: 100px;
    position: relative;
    text-transform: uppercase;
}

.item-partner {
    background: rgb(255 255 255 / 75%);
    border-radius: 5px;
}
img.avatar-team {
    border-radius: 100%;
    height: 130px;
    margin-bottom: 12px;
    object-fit: cover;
    width: 130px;
}
div#sc_team {
    background: hsl(32.73deg 94.29% 72.55%);
   }
   
   h2.title-team {
       font-size: 64px;
       font-weight: bold;
       margin-bottom: 75px;
   }
   
   .item-team {
       margin: auto;
       max-width: 370px;
   }
   
   .item-team > span {
       font-weight: bold;
       font-size: 20px;
       margin-bottom: 19px;
       display: block;
       color: #795548;
   }
   
   .item-team div{
       font-weight: bold;
       font-size: 16px;
       color: #434343;
   }
   
  .text-r{
    color: #FF9800;
  } 
  div#sc_popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    z-index: 999999999999999;
    background: rgb(0 0 0 / 67%);
}
div#sc_popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    z-index: 999999999999999;
    background: rgb(0 0 0 / 67%);
}

#sc_popup .box-popup {
    max-width: 400px;
    width: 100%;
    margin: auto;
    margin-top: 50px;
}

.main-popup-bg img {
    width: 150px;
    margin-bottom: 20px;
}

.main-popup-bg {
    text-align: center;
    border: 3px solid #f9c55a;
    border-radius: 10px;
    background: linear-gradient(186deg, rgba(164,53,106,1) 0%, rgba(145,46,101,1) 27%, rgba(109,32,97,1) 59%, rgba(79,20,84,1) 100%);
    padding: 50px 20px;
    color: #fff;
}

.main-popup-bg button {
    background: #55de3d;
    color: #fff;
    font-weight: bold;
    border: 2px solid #f9c55a;
    padding: 6px 30px;
    border-radius: 25px;
    text-shadow: -1px -1px 0 #000,  
     1px -1px 0 #000,
    -1px  1px 0 #000,
     1px  1px 0 #000;
}

.main-popup-bg p {
    color: #ffffff;
    font-weight: bold;
}
div#sc_popup .main-popup > h3 {
    background: url('../../../public/images/title-popup.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    color: #fff;
    text-align: center; 
    font-size: 30px;
    padding: 44px 20px;
    position: relative;
    top: 74px;
    width: calc(100% + 92px);
    left: -45px;
}
div#sc_popup .main-popup h3 span {
    position: relative;
    top: -20px;
    font-weight: bold;
    text-shadow: -1px 4px 3px #4b3026;

}
button.btn-popup-close {
    position: absolute;
    z-index: 9;
    border: 0px;
    background: transparent;
    width: 40px;
    right: -8px;
}

.main-popup {
    position: relative;
}
.text-next{
    display: none;
}

@media(max-width:767px) {
    .ktc a {
        margin: 0px 10px;
    }
    .ktc {
        text-align: center;
    }
    .copyright{
        margin-top: 15px;;
        text-align: center;
    }
    #sc_popup .box-popup{
        padding: 0px 15px;
    }
    #sc_team:before{
        content: unset;
    }
    .main-timeline .title {
        font-size: 30px;
    }
    .text-dex {
        font-size: 18px;
    }
    .tabc {
        overflow-y: auto;
    }
    header .navbar a.nav-link{
        color: #fff !important;
    }
    header .navbar-toggler {
        background: #fff !important;
    }
    .navbar-toggler{
        color:#fff;
    }
    header.viewPage,
    header.viewPage nav.navbar ,
    header.home nav.navbar {
        background: #2f363e !important;
    }
    header.home > nav {
        background: transparent !important;
    }
    
    div#sc_banner {
        padding-top: 130px;
    }
    header.home nav.navbar{
        position: relative;
    }
    a.navbar-brand .logo {
        margin: 0 !important;
        position: absolute;
        top: 0px;
        z-index: 999999;
        left: calc(50% - 30px);
    }
    
    a.navbar-brand {
        text-align: center;
        margin: 0px;
    }
    
    header a.nav-link {
        padding-right: 0px !important;
    }
    .box-partners-content {
        padding: 40px 20px 100px;
    }
    
    .box-partners-content h2 {
        font-size: 28px;
    }
    
    .list-partner {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    .logo-footer .logo {
        width: 120px;
        margin: 0px;
    }

    .logo-footer {
        text-align: center;
        padding: 20px 0px;
    }

   
    .box-countdowntime span.item-time {
        font-size: 14px;
        padding: 10px 10px;
    }

    div#sc_about h2 {
        font-size: 50px;      
    }

    .title-about:before {
        top: 2px;
    }

    .logo-footer .logo {
        width: 120px;
        margin: 0px;
    }

    .logo-footer {
        text-align: center;
        padding: 20px 0px;
    }

    .box-presale {
        padding: 50px 45px;
    }

    div#sc_about h2 {
        font-size: 50px;       
    }
   
    .title-about:before {
        top: 2px;
    }

    img.iabout3 {
        bottom: auto;
        top: 20px;
        right: -105px;
    }

    div#sc_about {
        padding-bottom: 130px;
    }

    .icon-about {
        bottom: -55px;
        right: -75px;
    }

    img.iabout4 {
        display: none;
    }

    .logo-footer .logo {
        width: 120px;
        margin: 0px;
    }

    .logo-footer {
        text-align: center;
        padding: 20px 0px;
    }

    .title-about:before {
        top: 2px;
    }

    img.iabout3 {
        bottom: auto;
        top: 20px;
        right: -105px;
    }

    .icon-about {
        bottom: -55px;
        right: -75px;
    }

    img.iabout4 {
        display: none;
    }

    div#sc_roadmap .title-h {
        font-size: 40px;
        margin-top: 0px;
        margin-bottom: 30px;
    }
    .item-team {
        margin-bottom: 50px;
    }
    .timeline-content {
        width: 100% !important;
        margin-left: 0px !important;
    }

    .main-timeline .title {
        font-size: 30px;
    }
    
    .item-team {
        margin-bottom: 50px;
    }
    
    div#sc_partners h2 {
        color: #fff;
        font-size: 40px;
        margin-top: 0px;
        margin-bottom: 60px;
    }

    .timeline-icon {
        left: 0px;
    }

    .main-timeline .timeline:nth-child(1) .timeline-content:after {
        right: 30px;
    }

    .main-timeline .timeline:nth-child(3) .timeline-content:after {
        left: auto;
        top: calc(100% - 25px);
        right: 0px;
        transform: rotate(48deg);
    }

    .main-timeline .timeline:nth-child(6) .timeline-content:after {
        right: calc(100% + -170px);
        top: calc(100% - 18px);
    }

    .item-tokenomics {
        margin-bottom: 30px;
    }

    div#sc_banner h1 {
        font-size: 44px;
    }

    header.home.p-3.text-bg-white {
        position: relative;
    }

    .could5 {
        width: 220px;
        height: 40px;
    }

    .could3 {
        width: 200px;
        height: 40px;
    }

    #sc_tokenomics,
    #sc_roadmap,
    #sc_presale {
        background-size: cover;
    }
    .text-end ul li:nth-child(2) {
        display: none;
    }
    div#bg_contact {
        padding: 100px 20px !important;
        min-height: auto;
    }
    footer .container{
        border: 0px;
    }
    button.btn-contact {
        margin-bottom: 50px;
    }
    
    #sc_contact h2 {
        font-size: 50px;
    }
    
    .main-timeline:before {
        content: unset;
    }
    
    .timeline-content:after {
        content: unset;
    }
    
    div#sc_tokenomics h2 {
        font-size: 50px;
    }
    
    .tokenomics-info h2 span {
        color: #48a8a1;
    }
    
    button.btn-coming-soon {
        margin-bottom: 50px;
    }
    
    .ranking-item-stt span {
        font-size: 20px;
    }
    
    .ranking-item-stt {
        max-width: 35px;
    }
    
    .ranking-item {
        grid-template-columns: 35px auto auto;
    }
    
    .ranking-item-name {
        font-size: 20px;
    }
    
    .ranking-item-point {
        padding-top: 0px;
    }
    
    div#sc_about p {
        font-size: 16px;
    }
    
    .text-next {
        font-size: 12px;
    }
    
    .box-countdowntime span.item-time {
        background: transparent;
        min-width: 35px;
        padding: 0px;
    }
    
    .box-countdowntime span.item-time span.time-t {
        color: #d15d55;
    }
    
    .box-countdowntime span.item-time span.time-text {
        margin-bottom: 10px;
    }
    
    .view-sm button {
        font-size: 14px;
    }
    
    .gr-btn-payment button {
        padding: 5px 35px;
        font-size: 20px;
    }
    
    .gr-ip-payment label {
        font-size: 13px;
    }
    
    .total-wallet p {
        font-size: 14px;
    }
}