/* App.css */
.app-game {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgb(0 0 0 / 32%);
    padding: 50px 30px;
}

.app-game .score,
.app-game .moves,
.app-game .level {
    font-size: 24px;
    margin-bottom: 10px;
}

.app-game .grid {
    display: grid;
    grid-template-columns: repeat(6, 70px);
    grid-gap: 5px;
}

.app-game .cell {
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    cursor: pointer;
    background-color: rgb(240 240 240 / 81%);
    border: 1px solid #ddd;
    border-radius: 4px;
}

.app-game .cell:hover {
    background-color: #e0e0e0;
}

.app-game .selected {
    background-color: #ffeb3b;
    border: 1px solid #fbc02d;
}

.app-game .progress {
    width: 100%;
    background-color: #e0e0e0;
    border-radius: 4px;
    height: 20px;
    margin-bottom: 10px;
}

.app-game .progress {
    width: 100%;
    background-color: #ac6d2c;
    border-radius: 25px;
    height: 26px;
    margin-bottom: 25px;
    border: 2px solid #fff;
}

.app-game .game-over,
.win-message,
.level-up-message {
    font-size: 24px;
    color: #d9534f;
    margin: 10px;
}

.app-game .level-up-message {
    color: #4caf50;
}

.app-game .btn-start-game {
    letter-spacing: 1px;
    font-weight: bold;
    color: #fff;
    font-size: 24px;
    text-shadow: 1px 1px 2px #000, 0 0 5px #ff0000;
    background: #e79d3f;
    border-radius: 10px;
    display: block;
    margin-bottom: 20px;
    border: 4px solid #df7f38;
    border-left-width: 4px;
    border-right-width: 4px;
    height:75px;
    padding: 0px 20px;
    margin-top: 235px;
}

button.btn-start-game span {
    font-size: 18px;
    display: block;
}
   

.app-game .game-top-poin {
    display: grid;
    grid-template-columns: auto auto auto;
    text-align: center;
}

.app-game .progress .progress-bar {
    max-width: 100%;
}

.wapper-game-run {
    background: url('../../../../public/images/banner-home.jpeg');
    background-size: cover;
    min-height: 600px;
    padding: 0px;
    border-radius: 25px;
    overflow: hidden;
    display: grid;
}

img.fruit-icon {
    width: 100%;
}
.main-view-play-game {
    position: relative;
}

.message.message-next,
.message.message-gameover {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 68%);
    text-align: center;
}
.btn-next-game{
    background: url('../../../../public/images/bg-btn.png');
    border: 0px;
    background-size: 100% 100%;
    padding: 8px 52px 12px;
    letter-spacing: 1px;
    font-weight: bold;
    color: #fff;
    font-size: 24px;
    text-shadow: 1px 1px 2px #000, 0 0 5px #ff0000;
}
.main-message-next {
    padding-top: 88px;
}

.main-message-next h3 {
    color: #ffd750;
    font-size: 35px;
    font-weight: bold;
}

.main-message-next .text-level {
    font-size: 24px;
    font-weight: bold;
}

.main-message-next .text-level-icon {
    color: #ffda53;
    font-size: 49px;
}

.main-message-next .text-score {
    margin-bottom: 31px;
    font-size: 21px;
    margin-top: 10px;
}
.game-head {
    background: #e2af6f;
    border-radius: 10px;
    padding: 10px 5px 1px;
    display: block;
    margin-bottom: 20px;
    border: 8px solid #df7f38;
    border-left-width: 4px;
    border-right-width: 4px;
    position: relative;
}

.mute {
    position: absolute;
    top: -23px;
    right: 5px;
}

button.btn-mute {
    border: 0px;
    margin: 0px;
    background: #d45350;
    color: #fff;
    border-radius: 100%;
    font-size: 20px;
    position: relative;
    top: 4px;
    right: -22px;
    border: 2px solid;
    width: 40px;
    height: 40px;
}
button.btn-game-replay {
    color: #fff;
    font-size: 24px;
    background: #E91E63;
    border-radius: 100%;
    border: 4px solid #bb134c;
    width: 50px;
    line-height: 40px;
    height: 50px;
}

button.btn-turn-game {
    color: #fff;
    font-size: 18px;
    background: #4CAF50;
    border-radius: 25px;
    border: 0px solid #2d8730;
    line-height: 36px;
    margin-left: 30px;
    padding: 0px;
    padding-left: 0px;
    padding-right: 30px;
    position: relative;
    top: -6px;
}

button.btn-turn-game span {
    font-size: 13px;
    background: #2d8730;
    height: 50px;
    width: 50px;
    display: inline-block;
    line-height: 50px;
    border-radius: 100%;
    position: relative;
    left: -2px;
    margin-right: 10px;
}
.game-top-poin svg {
    color: #FFEB3B;
}
@media(max-width:767px){
    .app-game {
        padding: 20px 10px 120px;
        
    }
    
    .app-game .cell {
        width: 50px;
        height: 50px;
    }
    
    .app-game .grid {
        display: grid;
        grid-template-columns: repeat(6, 50px);
        grid-gap: 5px;
    }
    
    .app-game .progress {
        height: 15px;
        margin-bottom: 10px;
    }
}